
<template>
<main class="container">
      <br>
       
 


<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-label="Slide 1" aria-current="true"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" class=""></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3" class=""></button>
    </div>
    <div class=" row ">
        <hr>
        <button class="col-md-1 btn btn-secondary" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            Önceki ay
        </button>
        <div class="col-md-10"></div>
        <button class="col-md-1 btn btn-secondary" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            Sonraki ay
        </button>

    </div>
    <div class="carousel-inner">
        <div class="carousel-item active">

            <div class="calendar shadow bg-white p-5">

                <div class="d-flex align-items-center"><i class="fa fa-calendar fa-3x mr-3"></i>
                    <h2 class="month font-weight-bold mb-0 text-uppercase">Ağustos 2021</h2>
                </div>
                <p class="font-italic text-muted mb-5"></p>
                <ol class="day-names list-unstyled">
                    <li class="font-weight-bold text-uppercase">Pazartesi</li>
                    <li class="font-weight-bold text-uppercase">Salı</li>
                    <li class="font-weight-bold text-uppercase">Çarşamba</li>
                    <li class="font-weight-bold text-uppercase">Perşembe</li>
                    <li class="font-weight-bold text-uppercase">Cuma</li>
                    <li class="font-weight-bold text-uppercase">Cumartesi</li>
                    <li class="font-weight-bold text-uppercase">Pazar</li>
                </ol>

                <ol class="days list-unstyled">
                    <li class="outside">
                        <div class="date">26 </div>
                    </li>
                    <li class="outside">
                        <div class="date">27 </div>
                    </li>
                    <li class="outside">
                        <div class="date">28 </div>
                    </li>
                    <li class="outside">
                        <div class="date">29 </div>
                    </li>
                    <li class="outside">
                        <div class="date">30 </div>
                    </li>
                    <li class="outside">
                        <div class="date">31 </div>
                    </li>

                    <li>
                        <div class="date">1</div>
                    </li>
                    <li>
                        <div class="date">2</div>
                    </li>
                    <li>
                        <div class="date">3</div>
                    </li>
                    <li>
                        <div class="date">4</div>
                    </li>
                    <li>
                        <div class="date">5</div>
                    </li>
                    <li>
                        <div class="date">6</div>
                    </li>
                    <li>
                        <div class="date">7</div>
                    </li>
                    <li>
                        <div class="date">8</div>
                    </li>
                    <li>
                        <div class="date">9</div>
                    </li>
                    <li>
                        <div class="date">10</div>
                    </li>
                    <li>
                        <div class="date">11</div>
                    </li>
                    <li>
                        <div class="date">12</div>
                    </li>
                    <li>
                        <div class="date">13</div>
                    </li>
                    <li>
                        <div class="date">14</div>
                    </li>
                    <li>
                        <div class="date">15</div>
                    </li>
                    <li>
                        <div class="date">16</div>
                    </li>
                    <li>
                        <div class="date">17</div>
                    </li>
                    <li>
                        <div class="date">18</div>
                    </li>
                    <li>
                        <div class="date">19</div>
                    </li>
                    <li>
                        <div class="date">20</div>
                    </li>
                    <li>
                        <div class="date">21</div>
                    </li>
                    <li>
                        <div class="date">22</div>
                    </li>
                    <li>
                        <div class="date">23</div>
                    </li>
                    <li>
                        <div class="date">24</div>
                    </li>
                    <li>
                        <div class="date">25</div>
                    </li>
                    <li>
                        <div class="date">26</div>
                    </li>
                    <li>
                        <div class="date">27</div>
                    </li>
                    <li>
                        <div class="date">28</div>
                    </li>
                    <li>
                        <div class="date">29</div>
                    </li>
                    <li>
                        <div class="date">30</div>
                    </li>
                    <li>
                        <div class="date">31</div>
                        <div class="event bg-danger">Tam Metinlerin Gönderilmesi</div>
                    </li>
                    <li class="outside">
                        <div class="date">1</div>
                    </li>
                    <li class="outside">
                        <div class="date">2</div>
                    </li>
                    <li class="outside">
                        <div class="date">3</div>
                    </li>
                    <li class="outside">
                        <div class="date">4</div>
                    </li>
                    <li class="outside">
                        <div class="date">5</div>
                    </li>
                </ol>
            </div>
        </div>
        <div class="carousel-item">
            <div class="calendar shadow bg-white p-5">
                <div class="d-flex align-items-center"><i class="fa fa-calendar fa-3x mr-3"></i>
                    <h2 class="month font-weight-bold mb-0 text-uppercase">Eylül 2021</h2>
                </div>
                <p class="font-italic text-muted mb-5"></p>
                <ol class="day-names list-unstyled">
                    <li class="font-weight-bold text-uppercase">Pazartesi</li>
                    <li class="font-weight-bold text-uppercase">Salı</li>
                    <li class="font-weight-bold text-uppercase">Çarşamba</li>
                    <li class="font-weight-bold text-uppercase">Perşembe</li>
                    <li class="font-weight-bold text-uppercase">Cuma</li>
                    <li class="font-weight-bold text-uppercase">Cumartesi</li>
                    <li class="font-weight-bold text-uppercase">Pazar</li>
                </ol>

                <ol class="days list-unstyled">

                    <li class="outside">
                        <div class="date">30 </div>
                    </li>
                    <li class="outside">
                        <div class="date">31 </div>
                    </li>
                    <li>
                        <div class="date">1</div>
                    </li>
                    <li>
                        <div class="date">2</div>
                    </li>
                    <li>
                        <div class="date">3</div>
                    </li>
                    <li>
                        <div class="date">4</div>
                    </li>
                    <li>
                        <div class="date">5</div>
                    </li>
                    <li>
                        <div class="date">6</div>
                    </li>
                    <li>
                        <div class="date">7</div>
                    </li>
                    <li>
                        <div class="date">8</div>
                    </li>
                    <li>
                        <div class="date">9</div>
                    </li>
                    <li>
                        <div class="date">10</div>
                    </li>
                    <li>
                        <div class="date">11</div>
                    </li>
                    <li>
                        <div class="date">12</div>
                    </li>
                    <li>
                        <div class="date">13</div>
                    </li>
                    <li>
                        <div class="date">14</div>
                    </li>
                    <li>
                        <div class="date">15</div>
                        <div class="event bg-info">Değerlendirme sonuçlarının açıklanması</div>
                    </li>
                    <li>
                        <div class="date">16</div>
                    </li>
                    <li>
                        <div class="date">17</div>
                    </li>
                    <li>
                        <div class="date">18</div>
                    </li>
                    <li>
                        <div class="date">19</div>
                    </li>
                    <li>
                        <div class="date">20</div>
                    </li>
                    <li>
                        <div class="date">21</div>
                    </li>
                    <li>
                        <div class="date">22</div>
                    </li>
                    <li>
                        <div class="date">23</div>
                    </li>
                    <li>
                        <div class="date">24</div>
                    </li>
                    <li>
                        <div class="date">25</div>
                    </li>
                    <li>
                        <div class="date">26</div>
                    </li>
                    <li>
                        <div class="date">27</div>
                    </li>
                    <li>
                        <div class="date">28</div>
                    </li>
                    <li>
                        <div class="date">29</div>
                    </li>
                    <li>
                        <div class="date">30</div>
                    </li>
                    <li class="outside">
                        <div class="date">1</div>
                    </li>
                    <li class="outside">
                        <div class="date">2</div>
                    </li>
                    <li class="outside">
                        <div class="date">3</div>
                    </li>

                </ol>
            </div>
        </div>
        <div class="carousel-item">
            <div class="calendar shadow bg-white p-5">
                <div class="d-flex align-items-center"><i class="fa fa-calendar fa-3x mr-3"></i>
                    <h2 class="month font-weight-bold mb-0 text-uppercase">Ekim 2021</h2>
                </div>
                <p class="font-italic text-muted mb-5"></p>
                <ol class="day-names list-unstyled">
                    <li class="font-weight-bold text-uppercase">Pazartesi</li>
                    <li class="font-weight-bold text-uppercase">Salı</li>
                    <li class="font-weight-bold text-uppercase">Çarşamba</li>
                    <li class="font-weight-bold text-uppercase">Perşembe</li>
                    <li class="font-weight-bold text-uppercase">Cuma</li>
                    <li class="font-weight-bold text-uppercase">Cumartesi</li>
                    <li class="font-weight-bold text-uppercase">Pazar</li>
                </ol>

                <ol class="days list-unstyled">

                    <li class="outside">
                        <div class="date">27 </div>
                    </li>
                    <li class="outside">
                        <div class="date">28 </div>
                    </li>
                    <li class="outside">
                        <div class="date">29 </div>
                    </li>
                    <li class="outside">
                        <div class="date">30 </div>
                    </li>

                    <li>
                        <div class="date">1</div>
                    </li>
                    <li>
                        <div class="date">2</div>
                    </li>
                    <li>
                        <div class="date">3</div>
                    </li>
                    <li>
                        <div class="date">4</div>
                    </li>
                    <li>
                        <div class="date">5</div>
                    </li>
                    <li>
                        <div class="date">6</div>
                    </li>
                    <li>
                        <div class="date">7</div>
                    </li>
                    <li>
                        <div class="date">8</div>
                    </li>
                    <li>
                        <div class="date">9</div>
                    </li>
                    <li>
                        <div class="date">10</div>
                    </li>
                    <li>
                        <div class="date">11</div>
                    </li>
                    <li>
                        <div class="date">12</div>
                    </li>
                    <li>
                        <div class="date">13</div>
                    </li>
                    <li>
                        <div class="date">14</div>
                    </li>
                    <li>
                        <div class="date">15</div>
                    </li>
                    <li>
                        <div class="date">16</div>
                    </li>
                    <li>
                        <div class="date">17</div>
                    </li>
                    <li>
                        <div class="date">18</div>
                    </li>
                    <li>
                        <div class="date">19</div>
                    </li>
                    <li>
                        <div class="date">20</div>
                        <div class="event all-day begin end span-2 bg-warning">Sempozyum Tarihi</div>

                    </li>
                    <li>
                        <div class="date">21</div>
                    </li>
                    <li>
                        <div class="date">22</div>
                    </li>
                    <li>
                        <div class="date">23</div>
                    </li>
                    <li>
                        <div class="date">24</div>
                    </li>
                    <li>
                        <div class="date">25</div>
                    </li>
                    <li>
                        <div class="date">26</div>
                    </li>
                    <li>
                        <div class="date">27</div>
                    </li>
                    <li>
                        <div class="date">28</div>
                    </li>
                    <li>
                        <div class="date">29</div>
                    </li>
                    <li>
                        <div class="date">30</div>
                    </li>
                    <li>
                        <div class="date">31</div>
                    </li>
                    <li class="outside">
                        <div class="date">1</div>
                    </li>
                    <li class="outside">
                        <div class="date">2</div>
                    </li>
                    <li class="outside">
                        <div class="date">3</div>
                    </li>
                    <li class="outside">
                        <div class="date">4</div>
                    </li>
                    <li class="outside">
                        <div class="date">5</div>
                    </li>
                    <li class="outside">
                        <div class="date">6</div>
                    </li>
                    <li class="outside">
                        <div class="date">7</div>
                    </li>
                </ol>
            </div>
        </div>
    </div>

</div>





      <br>
    </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Takvim',
  components: {
  }
}
</script>

<style scoped>
    /*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
    .clearfix::after,
    .calendar ol::after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }

    /* ================
Calendar Styling */
    .calendar {
        border-radius: 10px;
    }

    .month {
        font-size: 2rem;
    }

    @media (min-width: 992px) {
        .month {
            font-size: 3.5rem;
        }
    }

    .calendar ol li {
        float: left;
        width: 14.28571%;
    }

    .calendar .day-names {
        border-bottom: 1px solid #eee;
    }

    .calendar .day-names li {
        text-transform: uppercase;
        margin-bottom: 0.2rem;
    }

    .calendar .days li {
        border-bottom: 1px solid #eee;
        min-height: 6rem;
    }

    .calendar .days li .date {
        margin: 0.5rem 0;
    }

    .calendar .days li .event {
        font-size: 0.75rem;
        padding: 0.4rem;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 4rem;
        margin-bottom: 1px;
    }

    .calendar .days li .event.span-2 {
        width: 200%;
    }

    .calendar .days li .event.begin {
        border-radius: 1rem 0 0 1rem;
    }

    .calendar .days li .event.end {
        border-radius: 0 1rem 1rem 0;
    }

    .calendar .days li .event.clear {
        background: none;
    }

    .calendar .days li:nth-child(n+29) {
        border-bottom: none;
    }

    .calendar .days li.outside .date {
        color: #ddd;
    }

    /*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/
</style>
